import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import loadable from "@loadable/component"

// Lazy load the PortfolioPage component using @loadable/component
const PortfolioPage = loadable(() => import("../components/Portfolio-Page"), {
  fallback: <div className="loading-fallback">Loading...</div>,
})

export default function Portfolio() {
  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/portfolio/" />
      </Helmet>

      <PortfolioPage />
    </Layout>
  )
}
